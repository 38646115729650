<template>
<div class="work-bench" v-loading.fullscreen.lock="loading">
  <div class="work-top flex-bet-top">
    <div class="work-title">工作台模板</div>
    <div>
      <el-form ref="form" :model="form" size="small" @submit.native.prevent>
        <el-form-item >
          <el-input v-model="form.search" @input="clearInput" @keyup.enter.native="goSearch"  placeholder="请输入搜索关键字">
          </el-input>
          <i @click="goSearch" class="el-icon-search search-btn"></i>
        </el-form-item>
      </el-form>
    </div>
  </div>
  <div class="model-list flex-start" >
    <div class="add-model flex-cent-col" v-if="add_template" @click="handleEdit()">
      <img src="@/assets/images/add.png" alt="添加自定义">
      <p>添加模板</p>
    </div>
    <div class="model-item" v-for="(item,index) in modelData" :key="index">
      <vue-hover-mask >
        <div class="top-bg">
          <div v-if="item.is_system==1" class="flag fz12 examples " style="background:#FFF;color:black;">示例</div>
          <div v-if="item.is_system==2" class="flag fz12 top-flag" style="background:#FCCF53;">免费</div>
          <div v-else-if="item.is_system==0 && item.temp_status===1" class="flag fz12 model-flag" >已启用</div>
<!--          <div v-else-if="item.is_system==0 && item.temp_status===0" class="flag fz12 no-flag">未发布</div>-->
          <div v-else-if="item.is_system==0 && item.temp_status===2" class="flag fz12 no-flag" >未启用</div>
          <div v-else-if="item.is_system==0 && item.temp_status===3" class="flag fz12 del-flag">已删除</div>
          <div class="bg-content">
            <img src="@/assets/images/bg_top.png" alt="" class="top-bg-img">
            <div v-if="item.is_system>0">
              <img v-for="(app,i) in item.modular" :key="i"
                   :src="app.show_image"
                   alt="" class="model-item-img">
            </div>
            <div v-else>
              <img v-for="(app,i) in item.modular" :key="i"
                   :src="getAppBg(app.modular_type,item.is_system)"
                   alt="" class="model-item-img">
            </div>
          </div>
        </div>
        <template v-slot:action >
          <el-dropdown trigger="click" size="small" @command="handleCommand" v-if=" item.is_system===0">
            <div class="edit-more el-dropdown-link"><i class="el-icon-more"></i></div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="beforeCom(item,'a')" v-show="item.temp_status===1 && use_template" :disabled="item.temp_status!==1">关闭</el-dropdown-item>
              <el-dropdown-item :command="beforeCom(item,'u')" v-show="item.temp_status===2 && use_template">启用</el-dropdown-item>
              <el-dropdown-item :command="beforeCom(item,'b')" v-show="item.temp_status===3">恢复</el-dropdown-item>
              <el-dropdown-item :command="beforeCom(item,'c')" v-show="item.temp_status===0 || item.temp_status===2" v-if="del_template">删除</el-dropdown-item>
              <el-dropdown-item :command="beforeCom(item,'d')" v-show="item.temp_status===3" v-if="del_template">彻底删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button v-if="edit_template && item.is_system==0" class="edit-model" @click="handleEdit(item.id,1)" size="small" round>编辑</el-button>
          <div class="is-system flex-cent-col" v-if="item.is_system>0">
            <el-button  class="edit-model" @click="handleEdit(item.id,2)" size="small" round>查看</el-button>
            <el-button  class="edit-model" @click="handleEdit(item.id,3)" size="small" round>复制</el-button>
          </div>
        </template>
      </vue-hover-mask>
      <div class="bot-name">{{item.temp_name}}</div>
    </div>
  </div>

  <el-dialog
      title="确定启用并推送给员工吗？"
      :visible.sync="dialogVisible"
      width="30%">
        <span>推送成功后，员工的企业微信-工作台页面会自动刷新，数据推送可能会有延时</span>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="modelLoading" @click="getUseModel">启用并推送</el-button>
      </span>
  </el-dialog>
<!--  ，您可以去“<el-button type="text" @click="goToAsync">设置—任务列表</el-button>”中查看推送进度-->
</div>
</template>

<script>
import {getModelList,delModel,deletes,useModel,recoveryModel,closeModel} from '@/api/workbench'
import VueHoverMask from 'vue-hover-mask'
export default {
  name: "Workbench",
  components: {
    VueHoverMask
  },
  data(){
    return{
      form:{
        search:'',//搜索关键字
      },
      loading:true,
      modelLoading:false,
      showSelect:false,//下拉框
      defaultImg:require('@/assets/images/model_bg.jpg'),//默认背景图
      modelData:[],
      modular:[],
      useId:null,
      dialogVisible:false,
    }
  },
  computed:{
    getAppBg(){
      return function (type,system){
        if(system==1){
          if(type=='keydata'){
            return require('@/assets/images/keydata.png')
          }else if(type=='image'){
            return require('@/assets/images/img.png')
          }else if(type=='list'){
            return require('@/assets/images/list.png')
          }else{
            return require('@/assets/images/webview.png')
          }
        }else{
          if(type=='keydata'){
            return require('@/assets/images/model1.png')
          }else if(type=='image'){
            return require('@/assets/images/model2.png')
          }else if(type=='list'){
            return require('@/assets/images/model3.png')
          }else{
            return require('@/assets/images/model4.png')
          }
        }

      }
    },
    edit_template(){
      return this.$store.state.listData.edit_template
    },
    del_template(){
      return this.$store.state.listData.del_template
    },
    use_template(){
      return this.$store.state.listData.use_template
    },
    add_template(){
      return this.$store.state.listData.add_template
    },
  },
  created(){
    this.getModelList(1,100)
  },
  methods:{
    getModelList(page,page_size,temp_name){
      getModelList({page, page_size, temp_name}).then(res=>{
        if(res.code===200){
          this.modelData=res.data.data;

        }else{
          this.$message.error('未获取到数据，请稍后再试')
        }
        setTimeout(()=>{
          this.loading=false
        },1500)
      }).catch(err=>{
        console.log(err)
        this.loading=false
      })
    },
    goSearch(){//搜索关键字
      if(this.form.search){
        if(this.form.search.length>30 || this.form.search.length<2){
          this.$message({
            message: '关键字仅限2到30个字以内',
            type: 'warning'
          })
        }else{
          this.loading=true;
          this.getModelList(1,100,this.form.search)
        }
      }else{
        this.$message({
          message: '请输入搜索关键字',
          type: 'warning'
        })
      }

    },
    clearInput(){
      if(!this.form.search){
        this.loading=true;
        this.getModelList(1,100)
      }
    },
    handleEdit(id,type){//新增/编辑模板
      this.$router.push({
        query:{id:id?id:null,type:type},
        path:'/home/model'
      })
    },
    beforeCom(row,command){
      return {
        'row':row,
        'command':command,
      }
    },
    handleCommand(command) {//选择启用禁用删除模板
      switch (command.command) {
        case "a"://关闭
          this.closeModel(command.row.id)
          break;
        case "b"://恢复
          this.recovery(command.row.id)
          break;
        case "c"://删除
          this.handleDelModel(command.row.id,1)
          break;
        case "d"://彻底删除
          this.handleDelModel(command.row.id,2)
          break;
        case "u"://启用
          this.isUseModel(command.row.id)
          break;
      }
    },
    recovery(id){
      recoveryModel({id:id}).then(res=>{
        if(res.code===200){
          this.$message({
            type:'success',
            message:res.message
          })
          this.getModelList(1,100)
        }else{
          this.$message.error('操作失败，请重试')
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    handleDelModel(id,type){
      if(type===1){
        delModel({id}).then(res=>{
          if(res.code===200){
            this.$message({
              type:'success',
              message:res.message
            })
            this.getModelList(1,100)
          }else{
            this.$message.error('操作失败，请重试')
          }
        })
      }else{
        deletes({id}).then(res=>{
          if(res.code===200){
            this.$message({
              type:'success',
              message:res.message
            })
            this.getModelList(1,100)
          }else{
            this.$message.error('操作失败，请重试')
          }
        })
      }

    },
    isUseModel(id){
      this.dialogVisible=true;
      this.useId=id
    },
    goToAsync(){
      this.$router.push('/home/set/asynchronous')
    },
    getUseModel(){
      this.modelLoading=true;
      useModel({id:this.useId}).then(res=>{
        if(res.code===200){
          this.$message({
            type:'success',
            message:res.message
          })
          this.getModelList(1,100)
        }else{
          this.$message.error('操作失败，请重试')
        }
        this.dialogVisible=false;
        this.modelLoading=false;
      }).catch(err=>{
        this.modelLoading=false;
        this.dialogVisible=false;
        console.log(err)
      })
    },
    closeModel(id){
      closeModel({id}).then(res=>{
        if(res.code===200){
          this.$message({
            type:'success',
            message:res.message
          })
          this.getModelList(1,100)
        }else{
          this.$message.error('操作失败，请重试')
        }
      })
    },

  },
}
</script>

<style lang="less" scoped>
.work-bench{
    background: #EEEEEE;
  min-height: calc(100vh - 170px);
  .work-top{
    padding: 20px 21px 0 15px;
    .work-title{
      font-weight: bold;
      font-size: 20px;
    }
    /deep/.el-form{
      position: relative;
      /deep/.el-input__inner{
        border-radius: 30px;
      }
      .search-btn{
        position: absolute;
        right:12px;
        top:9px;
        cursor: pointer;
      }
    }
  }
  .model-list{
      flex-wrap: wrap;
    .add-model{
      width: 224px;
      height: 324px;
      background: #FFFFFF;
      cursor: pointer;
      margin:31px 15px 28px;
    }
    .model-item{
      margin:20px 15px 20px;
      width: 224px;
      &:hover{
        -webkit-transform:translateY(-20px);
        transform: translateY(-20px);
        transition: all 0.25s ease-in-out;
        //margin-top: -20px;
      }
      .top-bg{
        height: 273px;
        width: 224px;
        position: relative;
        .bg-content{
          width: 100%;
          background: #f6f6f6;
          .top-bg-img{
            width: 100%;
          }
          .model-item-img{
            width: 214px;
            margin:3px 5px;
          }

        }
        .flag{
          width: 56px;
          height: 24px;
          border-radius: 2px 0 16px 0;
          color: #FFFFFF;
          position: absolute;
          top:0;
          left:0;
          text-align: center;
          line-height: 24px;
        }
        .top-flag{
          background: #FCCF53;
        }
        .no-flag{
          background: #CFCFCF;
        }
        .model-flag{
          background: #67C23A;
        }
        .del-flag{
          background: #ff3a3a;
        }
      }
      .vue-hover-mask_action{
        position: relative;
      }
      .vue-hover-mask{
        border-top-left-radius:6px !important;
        border-top-right-radius:6px !important;
        border-bottom-right-radius:0px !important;
        border-bottom-left-radius:0px !important;
      }
      .is-system{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 56px;
        margin-top: -32px;
        margin-left: -28px;
        .el-button.edit-model{
          margin-left: 0;
          margin-bottom: 5px;
        }
      }
      .edit-model{
        background:rgba(0,0,0,0.1) ;
        color:#fff;
      }
      .el-dropdown{
        position: absolute;
        right:15px;
        top:15px;
      }
      .edit-more{
        background-color: #7F7C79;
        color:#fff;
        border-color: #7F7C79;
        height: 16px;
        font-size: 14px;
        width: 28px;
        line-height: 16px;
        border-radius: 10px;
      }
      .bot-name{
        background: #FFFFFF;
        line-height: 51px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-left: 15px;
        font-size: 14px;
        height: 51px;
      }
    }
  }
  .examples{
    opacity:0.9;
  }
}
</style>